<template>
  <div class="school-block-main">
    <div class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb :items="timelinePage" />
      </div>
    </div>
    <b-card>
      <div class="title-page-schoolblock pb-2">
        <h4>Quản lý khối trong phòng ban: {{textDepartment}}</h4>
      </div>
      <div class="search-school-block-main">
        <b-row>
          <b-col lg="4">
            <div class="search-school-block-input">
              <b-form-input v-model="modelSchoolBlockSearch.name" @change="getListAllSchoolBlock" type="text" placeholder="Nhập tên khối để tìm kiếm" />
            </div>
          </b-col>
          <b-col lg="4">
            <div class="acction-search-school-block">
              <b-button
                  @click="getListAllSchoolBlock"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
                />
                <span class="align-middle">Tìm kiếm</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="data-table-school-block mt-2">
        <b-row>
          <b-col lg="12">
            <div class="top-table-school-block">
              <b-row>
                <b-col>
                  <div class="select-perpage-department">
                    <b-form-group
                    >
                      <label class="d-inline-block text-sm-left mr-50">Số bản ghi trên một trang</label>
                      <b-form-select
                        id="perPageSelect"
                        v-model="perrPage"
                        size="sm"
                        :options="listPerPageSchoolBlock"
                        class="w-50"
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="10" style="margin: auto">
                  <div class="acction-add-school-block">
                    <b-button @click="getFormCreateSchoolBlock"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Thêm mới</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table-school-block-data">
              <b-table
                :per-page="perrPage"
                :current-page="currentPage"
                :fields="fieldSchoolBlock"
                :items="listSchoolBlock">
                <template #cell(index)="data">
                  <span>{{data.index + 1}}</span>
                </template>
                <template #cell(organizationBranchName)="data">
                   <span class="custom-a"><a type="button" @click="redirectPageGroup(data.item)"> {{data.value}}</a></span>
                </template>
                <template #cell(hanhdong)="data">
                  <b-button @click="getFormUpdateSchoolBlock(data.item.id)" v-b-tooltip.hover.top="'Cập nhật'" variant="flat-primary" class="btn-icon"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button v-b-tooltip.hover.top="'Xóa'"
                    variant="flat-danger"
                    class="btn-icon" @click="deletSchoolBlock(data.item)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </template>
              </b-table>
              <b-pagination
                :total-rows="rows"
                class="float-right mt-2"
                v-model="currentPage"
                :per-page="perrPage"
                hide-goto-end-buttons>
              </b-pagination>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal ref="modal-school-block"
      size="sm"
      hide-header
      hide-footer>
      <div class="title-header-modal">
        <h4 class="card-title">{{isSchoolBlock? 'Cập nhật' : 'Tạo mới'}} khối trong phòng ban: {{textDepartment}}</h4>
      </div>
      <validation-observer ref="modelSchoolBlock">
        <div class="main-modal-school-block">
          <b-form-group>
            <validation-provider #default="{ errors }" name="Tên khối không được để trống" rules="required">
              <label class="font-weight-bold">Tên khối</label>
              <b-form-input :class="errors.length > 0 ? 'is-invalid':null" v-model="modelSchoolBlock.organizationBranchName" placeholder="Nhập tên khối"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="footer-modal-school-block">
          <b-button v-if="!isSchoolBlock" size="sm" class="mr-1" @click="addSchoolBlock"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
          >
            <feather-icon icon="CheckIcon" />
            <span class="align-middle">Tạo mới</span>
          </b-button>
          <b-button v-if="isSchoolBlock" size="sm" class="mr-1" @click="updateSchoolBlock"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
          >
            <feather-icon icon="CheckIcon" />
            <span class="align-middle">Cập nhật</span>
          </b-button>
          <b-button size="sm" @click="closeModal"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
          >
            <feather-icon icon="XIcon" />
            <span class="align-middle">Đóng</span>
          </b-button>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, VBTooltip, BFormInput, BButton, BTable, BModal, BFormGroup, BPagination, BBreadcrumb, BFormSelect,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BModal,
    BFormGroup,
    BPagination,
    BBreadcrumb,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      idSchoolBlock: null,
      listPerPageSchoolBlock: [
        3, 5, 10,
      ],
      modelSchoolBlock: {
        parentId: null,
        organizationBranchName: '',
      },
      modelSchoolBlockSearch: {
        departmentId: '',
        name: '',
        pageIndex: 0,
        pageSize: 0,
      },
      required,
      isSchoolBlock: false,
      perrPage: 10,
      currentPage: 1,
      textDepartment: '',
      listSchoolBlock: [],
      idDepartment: null,
      fieldSchoolBlock: [
        {
          key: 'index', label: 'Stt', thClass: 'custom-th-school-block-stt',
        },
        {
          key: 'organizationBranchName', label: 'Tên khối',
        },
        {
          key: 'hanhdong', label: 'Hành động', thClass: 'custom-th-school-block', tdClass: 'custom-td-school-block',
        },
      ],
    }
  },
  created() {
    const parameters = this.$route.query
    this.idDepartment = parameters.id
    this.modelSchoolBlock.parentId = parameters.id
    if (parameters) {
      this.textDepartment = parameters.department
      this.timelinePage = [
        {
          text: 'Phòng ban',
          href: '/department',
        },
        {
          text: `${this.textDepartment}`,
          href: '/department',
        },
        {
          text: 'Khối',
        },
      ]
    }
    this.getListAllSchoolBlock()
  },
  methods: {
    getListAllSchoolBlock() {
      this.$crm.post(`organization-branch-school/find-all-group-by-condition/${this.idDepartment}`, this.modelSchoolBlockSearch).then(res => {
        this.listSchoolBlock = res.data
      })
    },
    getFormCreateSchoolBlock() {
      this.isSchoolBlock = false
      this.modelSchoolBlock.organizationBranchName = ''
      this.$refs['modal-school-block'].show()
    },
    closeModal() {
      this.$refs['modal-school-block'].hide()
    },
    addSchoolBlock() {
      this.$refs.modelSchoolBlock.validate().then(success => {
        if (success) {
          this.$crm.post('organization-branch-school/create-group', this.modelSchoolBlock).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Tạo mới khối thành công',
              },
            })
            this.getListAllSchoolBlock()
            this.$refs['modal-school-block'].hide()
          })
        }
      })
    },
    updateSchoolBlock() {
      this.$refs.modelSchoolBlock.validate().then(success => {
        if (success) {
          this.$crm.post(`organization-branch-school/update-group/${this.idSchoolBlock}`, this.modelSchoolBlock).then(res => {
            this.modelSchoolBlock = res.data
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Cập nhật khối thành công',
              },
            })
            this.$refs['modal-school-block'].hide()
            this.getListAllSchoolBlock()
          })
        }
      })
    },
    getFormUpdateSchoolBlock(id) {
      this.isSchoolBlock = true
      this.idSchoolBlock = id
      if (id) {
        this.$crm.post(`organization-branch-school/find-group-by-id/${id}`).then(res => {
          this.modelSchoolBlock = res.data
          this.$refs['modal-school-block'].show()
        })
      }
    },
    deletSchoolBlock(item) {
      this.$swal({
        title: 'Xóa khối',
        text: `Bạn có muốn xóa khối ${item.organizationBranchName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.get(`organization-branch-school/delete-organization-branch/${item.id}`).then(res => {
            this.getListAllSchoolBlock()
            this.$swal({
              icon: 'success',
              title: 'Xóa khối',
              text: 'Xóa khối thành công',
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
    redirectPageGroup(item) {
      this.$router.push({
        name: 'department-school-group',
        query: {
          idDepartment: this.idDepartment,
          nameDepartment: this.textDepartment,
          idSchoolBlock: item.id,
          nameSchoolBlock: item.organizationBranchName,
        },
      })
    },
  },
  computed: {
    rows() {
      if (this.listSchoolBlock) {
        return this.listSchoolBlock.length
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../@core/scss/custom/department';
</style>
